import logo from "../images/logo.png"
import ogLogo from "../images/logo-color.png"
import logoColor from "../images/color-logo.png"

import homeBanner from "../../resources/images/home-banner.svg"
import homeBanner2 from "../../resources/images/home-banner.png"
import wave from "../../resources/images/wave.svg"
import halfCircle from "../../resources/images/half-circle.svg"
import favicon16 from "../../resources/images/fav-16x16.png"
import home from "../../resources/images/home.svg"
import arrowLeft from "../../resources/images/arrow-left.svg"
import arrowRight from "../../resources/images/arrow-right.svg"
import customerCare from "../../resources/images/customer-care.svg"
import dottedHalfCircle from "../../resources/images/dotted-half-circle.svg"
import redDottedHalfCircle from "../../resources/images/red-circle-dotted.svg"
import greyWave from "../../resources/images/grey-wave.svg"
import lacCircle from "../../resources/images/licence-and-approval-circles.png"
import shareButton from "../../resources/images/share-button.svg"
import cliniExpertsLogo from "../../resources/images/cliniexperts-logo.svg"
import triangle from "../../resources/images/triangle.svg"

import artboard_1 from "../../resources/images/background-images/a1.svg"
import artboard_2 from "../../resources/images/background-images/a2.svg"
import artboard_3 from "../../resources/images/background-images/a3.svg"
import artboard_4 from "../../resources/images/background-images/a4.svg"
import artboard_5 from "../../resources/images/background-images/a5.svg"
import artboard_6 from "../../resources/images/background-images/a6.svg"
import artboard_7 from "../../resources/images/background-images/a7.svg"
import artboard_8 from "../../resources/images/background-images/a8.svg"
import artboard_9 from "../../resources/images/background-images/a9.svg"

import backCircleIconGrey from "../../resources/images/background-images/circledark.svg"
import backCircleIconDark from "../../resources/images/background-images/circlegrey.svg"
import backDotsIconGrey from "../../resources/images/background-images/dotdark.svg"
import backDotsIconDark from "../../resources/images/background-images/dotgrey.svg"
import backHalfTriangleIconDark from "../../resources/images/background-images/triangledark.svg"
import backHalfTriangleIconGrey from "../../resources/images/background-images/trianglegrey.svg"



const images = {
    logo,
    ogLogo,
    logoColor,
    homeBanner,
    homeBanner2,
    wave,
    halfCircle,


    cliniExpertsLogo,
    favicon16,
    home,
    arrowLeft,
    arrowRight,
    customerCare,
    dottedHalfCircle,
    redDottedHalfCircle,
    greyWave,
    lacCircle,
    shareButton,
    triangle,

    artboard_1,
    artboard_2,
    artboard_3,
    artboard_4,
    artboard_5,
    artboard_6,
    artboard_7,
    artboard_8,
    artboard_9,
    backCircleIconDark,
    backCircleIconGrey,
    backDotsIconDark,
    backDotsIconGrey,
    backHalfTriangleIconDark,
    backHalfTriangleIconGrey,
}
export default images
