import React  from "react"
import images from "./images";
import {Link} from "gatsby";

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = { value: "" }
    this.state = { text: "" }
  }

  render() {
    return (
        <footer id="footer" className="footer">
            <div className="container-fluid theme-footer-bg-blue">
                <div className="container">
                    <div className="row py-4">
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className={"py-lg-5"}>
                                <img src={images.logoColor} className="img-fluid" alt={"FSM logo"} style={{maxHeight:'58px'}}/>
                                <p className="fsm-theme-small-text  color-white mt-3">
                                    An initiative of CliniExperts that brings all the latest updates and information regarding legal metrology regulations, mandates and compliances issued by government and independent authorities explained in a simplified manner
                                </p>
                            </div>
                        </div>
                        <div className="col-12  col-md-6 col-lg-2 offset-lg-1 quicklink">
                            <div className={"py-lg-5"}>
                                <h5 className="text-white fsm-theme-subheading">Quick Link</h5>
                                <ul className="list-style-type-none pl-0">
                                    <li><Link to={"/about-us/"} href="#" className="color-white fsm-theme-small-text">About us</Link></li>
                                    <li><Link to={"/privacy-policy/"} className="color-white fsm-theme-small-text">Privacy & Policy</Link></li>
                                    <li><Link to={"/contact-us/"} className="color-white fsm-theme-small-text">Contact us</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12  col-lg-4 offset-lg-1">
                            <div  className={"p-lg-5"}>
                                <img src={images.cliniExpertsLogo} className="img-fluid" style={{maxWidth:'220px'}} alt={"CliniExperts logo"} />
                                <p className="fsm-theme-small-text color-white mt-3">
                                    End-To-End Regulatory Solution Provider for Pharmaceutical, Food, Nutraceutical, Cosmetic, Medical Device, Diagnostics Kits, Biologicals and Vaccine Industry.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid py-2" style={{backgroundColor:'#3b3f4a'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 pr-0 pr-md-3"><small className="color-white">All rights are reserved @ {(new Date().getFullYear())}</small></div>
                       {/* <div className="col-6 text-center">
                            <ul className="list-group list-group-horizontal list-style-type-none float-md-right">
                                <li className="list-group-item bg-transparent border-0 p-0"><Link to={'https://www.facebook.com/foodsafetymantra'} target={'_blank'}><div className="icon-circle border rounded-circle bd-white p-2" style={{height:'30px',width:'30px'}}><i className="fa fa-facebook-f text-white"> </i></div> </Link></li>
                                <li className="list-group-item bg-transparent border-0 p-0"><Link to={'https://www.linkedin.com/company/cliniexperts'} target={'_blank'}><div className="icon-circle border rounded-circle bd-white p-2" style={{height:'30px',width:'30px'}}><i className="fa fa-linkedin text-white" style={{top: '-6px'}}> </i></div> </Link></li>
                                <li className="list-group-item bg-transparent border-0 p-0"><Link to={'https://twitter.com/fsmantra'} target={'_blank'}><div className="icon-circle border rounded-circle bd-white p-2" style={{height:'30px',width:'30px'}}><i className="fa fa-twitter text-white"> </i></div> </Link></li>
                                <li className="list-group-item bg-transparent border-0 p-0"><a href='mailto:info@example.com?&subject=&cc=&bcc=&body=https://www.foodsafetymantra.com/'><div className="icon-circle border rounded-circle bd-white p-2" style={{height:'30px',width:'30px'}}><i className="fa fa-envelope text-white" style={{ left: '-1px',top: '-6px'}}> </i></div> </a></li>
                            </ul>
                        </div>*/}
                    </div>
                </div>
            </div>

        </footer>

    )
  }
}
export default Footer
