import React from "react"
import Images from "../components/images"
import ReadMoreBtn from "./read-more-btn";
import {Link} from "gatsby";

class Header extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            headerClass:''
        }
    }


    listenScrollEvent = e => {
        if (window.scrollY > 100) {
            this.setState({
                headerClass:
                    "sticky",
            })
        } else {
            this.setState({
                headerClass:"",
            })
        }
    }

    componentDidMount() {
        window.addEventListener("scroll", this.listenScrollEvent)
    }


    render() {
        return (
            <header id="header" className={"container-fluid theme-bg-blue "+this.state.headerClass}>
                <nav className="container navbar navbar-expand-lg navbar-light pt-1 pb-1">
                    <a className="navbar-brand header-logo-width" href="/">
                        <img src={Images.logoColor} style={{maxHeight: '48px'}}/>
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"> </span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">

{/*
                        <div className="border-white btn-pill text-center color-white w-auto border p-2 d-block d-lg-none d-xl-block">Your Guide To FSSAI Food Safety News and Updates</div>
*/}

                        <ul className="navbar-nav ml-auto text-center">
                            <li className="nav-item active">
                                <a className="nav-link text-white mr-3 font-bold" href="tel:+917672005050"><i className="fa fa-phone"> </i>  +91 7672005050</a>
                            </li>
                            <li className="nav-item active">
                                <a className="nav-link text-white mr-3 font-bold" href="mailto:contact@metrifyindia.com"><i className="fa fa-envelope"> </i> contact@metrifyindia.com</a>
                            </li>
                            <li className="nav-item">
                                <ReadMoreBtn type={'link-trans'} url={'/contact-us/'} text={'CONTACT US'}/>
                            </li>
                        </ul>

                    </div>
                </nav>
            </header>

        )
    }
}

export default Header
